<template>
    <div class="homeMain">
        <div class="swiper-container swiper-no-swiping banan">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,index) in 4">
                    <img src="../../assets/indexBanan1.png" alt="">
                </div>
            </div>
            <!-- <div class="bananPre" v-if="swipers.activeIndex!=0" @click="changePre1">
                <img src="../../assets/btn_pre.png" alt="">
            </div>
            <div class="bananNext" v-if="swipers.activeIndex!=3"  @click="changeNext1">
                <img src="../../assets/btn_next.png" alt="">
            </div> -->
        </div>
        <!-- 探索新知 -->
        <div class="explore" data-aos="fade-up" data-aos-once='true'>
            <div class="exploreContain">
                <div class="exploreTitle">
                    <h1>探索新知，共同成长</h1>
                    <p>科学的教学体系，丰富的学习内容；</p>
                    <p>资深教研团队和运营团队共同策划各类型匹配市场需求的专题课程及微课课程。</p>
                </div>
                <div class="exploreList">
                    <div class="exploreList_item" data-aos="fade-up" data-aos-once='true'>
                        <div class="exploreLeft">
                            <img src="../../assets/homeList1.png" alt="" />
                        </div>
                        <div class="exploreRigth type1">
                            <div class="item_Cire">
                                <img class="imgMargin1" src="../../assets/smallCire.png" alt="" />
                                <img src="../../assets/bigCire.png" alt="" />
                            </div>
                            <div class="item_rightContent">
                                优质<b>4K级</b>专题、学科精品课程
                            </div>
                        </div>
                    </div>
                    <div class="exploreList_item" data-aos="fade-up" data-aos-once='true'>
                        <div class="exploreRigth type2">
                            <div class="item_Cire">
                                <img src="../../assets/bigCire.png" alt="2" />
                                <img class="imgMargin1" src="../../assets/smallCire.png" alt="1" />
                            </div>
                            <div class="item_rightContent">
                                <b>素质类</b>教育课程
                            </div>
                        </div>
                        <div class="exploreLeft">
                            <img src="../../assets/homeList2.png" alt="" />
                        </div>
                    </div>
                    <div class="exploreList_item" data-aos="fade-up" data-aos-once='true'>
                        <div class="exploreLeft">
                            <img src="../../assets/homeList3.png" alt="" />
                        </div>
                        <div class="exploreRigth type1">
                            <div class="item_Cire">
                                <img class="imgMargin1" src="../../assets/smallCire.png" alt="" />
                                <img src="../../assets/bigCire.png" alt="" />
                            </div>
                            <div class="item_rightContent">
                                <b>内容+AI</b>自测体系建设
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 优质普惠 -->
        <div class="quality" data-aos="fade-up" data-aos-once='true'>
            <div class="qualityContain">
                <h1>优质普惠的知识内容</h1>
                <div class="qualityList">
                    <div class="qualityItem">
                        <div class="itemImg">
                            <img src="../../assets/homeList4.png" alt="">
                            <div class="imgText">
                                <p>幼儿+K12阶段学生及家长</p>
                            </div>
                        </div>
                        <p>
                            受众广
                        </p>
                    </div>
                    <div class="qualityItem">
                        <div class="itemImg">
                            <img src="../../assets/homeList5.png" alt="">
                            <div class="imgText">
                                <p>以书本课文、例题解析为主，有助于学生零基础自习。</p>
                            </div>
                        </div>
                        <p>
                            零基础
                        </p>
                    </div>
                    <div class="qualityItem">
                        <div class="itemImg">
                            <img src="../../assets/homeList6.png" alt="">
                            <div class="imgText">
                                <p>在线学习，完整地呈现课堂式教学</p>
                            </div>
                        </div>
                        <p>
                            全覆盖
                        </p>
                    </div>
                    <div class="qualityItem">
                        <div class="itemImg">
                            <img src="../../assets/homeList7.png" alt="">
                            <div class="imgText">
                                <p>课程更新速度快，每月不 低于50集课程的更新。</p>
                            </div>
                        </div>
                        <p>
                            更新快
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 还原教材 -->
        <div class="textbook"  data-aos="fade-up" data-aos-once='true'>
            <div class="textbookContain">
                <div class="textbookTitle">
                    <h1>高度还原教材内容，不超纲，不超前</h1>
                    <p>100%还原教材，在还原书本的基础上，因材施教，根据不同年级，科目；</p>
                    <p>制定科学性、系统性的课程体系，打造一个互动式在线课堂，助力学生自主学习，提升成绩。</p>
                </div>
                <div class="textbookList">
                    <div class="listNav">
                        <div class="listNav_item" :class="index==textBookIndex?'textBookAcitve':''" v-for="(item,index) in textBook" @click="changeTextBook(index)">
                            {{item.title}}
                        </div>
                    </div>
                    <div class="listContain" v-if="changeShow" data-aos="fade-up" data-aos-once='true'>
                        <div class="textBook_item" data-aos="fade-up" :data-aos-delay="index*200" :style="{background:'rgba(59, 205, 13, '+(index*3)/100+')'}" v-for="(item,index) in textBook[textBookIndex].text">
                            <span>{{index+1}}</span>
                            <p>{{item}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 优选教师 -->
        <div class="teacher" data-aos="fade-up" data-aos-once='true'>
            <div class="teacherContain">
                <div class="teacherTitle">
                    <h1>优选讲师</h1>
                    <p>新芽教育一直打造集优秀教师为一体、课程教材同步本地化、运营专题更新快的垂直互动课程产品服务。</p>
                    <p>汇聚国内名校最优秀的学习课程资源内容。</p>
                </div>
                <div class="swiper-container swiper-no-swiping teacherBanan" data-aos="fade-up" data-aos-once='true'>
                    <div class="swiper-wrapper">
                        <div class="swiper-slide teacherChild" v-for="(item,index) in teachBanner">
                            <div class="teacherLeft">
                                <img :src="item.img" alt="">
                            </div>
                            <div class="teacherRight">
                                <div class="teacherName">
                                    <h1>{{item.name}}</h1>
                                    <span>{{item.subject}}</span>
                                </div>
                                <div class="teacherBrief">
                                    <p>教师简介：</p>
                                    <p>{{item.brief}}</p>
                                </div>
                                <div class="teacherBrief">
                                    <p class="label">教学风格：</p>
                                    <p>{{item.style}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bananPre" v-if="swiper2Index!=0" @click="changePre2">
                        <img src="../../assets/btn_pre.png" alt="">
                    </div>
                    <div class="bananNext" v-if="swiper2Index!=teachBanner.length-1" @click="changeNext2">
                        <img src="../../assets/btn_next.png" alt="">
                    </div>
                </div>
                <div class="teacherList" data-aos="fade-up" data-aos-once='true'>
                    <div class="teacherItem" v-for="(item,index) in 10">
                        <img :src="require('../../assets/teacher'+(index+1)+'.png')" alt="" />
                    </div>
                </div>
            </div>
        </div>  
    </div>
</template>
<script>

export default {
    name:'home',
    data(){
        return{
            swipers:{},
            swiper2:{},
            swiperIndex:0,
            swiper2Index:0,
            textBookIndex:0,
            textBook:[
                {
                    title:'小学语文',
                    text:[
                        "作者介绍 ",
                        "课前思考（引起孩子好奇心和学习兴趣）",
                        "课文学习：提问回答、词语解释、句型讲解、 划出重点",
                        "课堂练习（精选题讲解）",
                        "词语累积",
                        "生字带读、学习、易错字讲解",
                        "课堂小结"
                    ]
                },
                {
                    title:'小学数学',
                    text:[
                        "课题导入，温故知新",
                        "情景导入，兴趣引导",
                        "探索新知，例题精讲 （用数学知识解决生活问题，实际应用）",
                        "经典练习，常考题型",
                        "课堂练习（精选题讲解）",
                        "课堂总结，回顾课堂重难点"
                    ]
                },
                {
                    title:'小学英语',
                    text:[
                        "单词学习：中文+英文+图片",
                        "课文学习：中文+英文+图片",
                        "带读：慢速、常速、快速",
                        "课文讲解+知识精讲+课文总结",
                        "重点句型讲解",
                        "课文笔记",
                        "随堂练习（精选题讲解）+作业布置",
                        "日积月累学英文：每节课末学习一个课 外有趣的新句子"
                    ]
                }
            ],
            teachBanner:[
                {
                    img:require('../../assets/1.jpg'),
                    name:'萌萌老师',
                    subject:'小学数学主讲老师',
                    brief:'数学知识丰富，思维清晰严谨，教学经验扎实，不断探索数学教育规律，钻研教学新方法，是孩子心中的良师益友！',
                    style:'教学语言清新自然，擅长把数学中的重难点融汇进教学当中，提高学生学习主动性。',
                },
                {
                    img:require('../../assets/2.jpg'),
                    name:'竹子老师',
                    subject:'小学语文主讲老师',
                    brief:'优秀学科教师，现任职于某知名学校。着重培养学生的学习兴趣，致力开发学生的学习潜能，有丰富的教学经验。',
                    style:'颇具亲和力，活泼生动，专业知识丰富，教学方法多样化。',
                },
                {
                    img:require('../../assets/3.jpg'),
                    name:'文娟老师',
                    subject:'小学数学主讲老师',
                    brief:'知名师范院校毕业，一直从事小学数学教学工作。强调学习方法的引导，帮助学生理清解题思路，掌握解题方法。教学中，注重题型的归纳，典型例题讲解透彻。',
                    style:'幽默风趣、语言生动，富有开拓性。',
                },
                {
                    img:require('../../assets/4.jpg'),
                    name:'乔乔老师',
                    subject:'小学数学主讲老师',
                    brief:'讲课有激情，生动形象，讲题思路清晰，条理，能够结合身边的例子旁征博引，使得课程鲜活，充实。内容通俗易通，待人真诚友善，对教育事业有高度的热情。',
                    style:'善于巧用妙用各种合理有效的手段和方法因人施教，因材施教，课堂生动活泼，妙趣横生。',
                },
                {
                    img:require('../../assets/5.jpg'),
                    name:'静静老师',
                    subject:'小学语文主讲老师',
                    brief:'深耕小升初学段，擅于把握知识重难点。知识讲解深入浅出，层次分明。课文解读重于提炼关键，阅读理解强于把握技巧。',
                    style:'教学风格颇具亲和力，活泼生动，专业知识丰富，教学方法多样化。使枯燥的知识简单有趣，易掌握且更加实用。',
                },
            ],
            changeShow:true,
        }
    },
    mounted(){
        
        let that = this;
        
        this.swipers = new Swiper('.banan', {
            direction: "horizontal",
	    	loop: false,
	    	observer: true,
	    	observeParents: true,
            
        });
        this.swiper2 = new Swiper('.teacherBanan', {
            direction: "horizontal",
	    	loop: false,
            delay:8000,
            autoplay:true,
	    	observer: true,
            disableOnInteraction:true,
	    	observeParents: true,
            on:{
                slideChangeTransitionEnd(swiper){
                    that.$nextTick(()=>{
                        that.swiper2Index = swiper.activeIndex
                    })
                    
                }
            }
        });
        
    },
    methods:{
        changeTextBook(n){
            if(this.textBookIndex==n) return false;
            this.textBookIndex = n
            this.changeShow = false;
            setTimeout(() => {
                this.changeShow = true;
            }, 100);
        },
        changePre1(){
            this.swipers.slidePrev()
        },
        changeNext1(){
            this.swipers.slideNext()
        },
        changePre2(){
            this.swiper2.slidePrev()
        },
        changeNext2(){
            this.swiper2.slideNext()
            
        },
    }
}
</script>
<style lang="scss">
.homeMain{
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    .banan{
        width: 100%;
        height: auto;
        position: relative;
        .swiper-slide{
            width: 100%;
            height: 100%;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .bananPre{
            position: absolute;
            top:50%;
            left:10%;
            transform: translateY(-50%);
            width: 68px;
            height: 68px;
            z-index: 2;
            img{
                width:100%;
                height: 100%;
            }
        }
        .bananNext{
            position: absolute;
            top:50%;
            right:10%;
            transform: translateY(-50%);
            width: 68px;
            height: 68px;
            z-index: 2;
            img{
                width:100%;
                height: 100%;
            }
        }
    }
    .explore{
        width: 100%;
        background: #FFFFFF;
        &Contain{
            width: 1200px;
            margin: 0 auto;
            padding: 100px 0 136px;
            .exploreTitle{
                text-align: center;
                h1{
                    display: inline-block;
                    position: relative;
                    font-size: 38px;
                    font-weight: bold;
                    color: #000000;
                }
                h1::before{
                    position: absolute;
                    top: 50%;
                    left: -90px;
                    display: block;
                    content: '';
                    background: url('../../assets/titleLeft.png') no-repeat;
                    width: 74px;
                    height: 3px;
                    transform: translateY(50%);
                }
                h1::after{
                    position: absolute;
                    top: 50%;
                    right: -90px;
                    display: block;
                    content: '';
                    background: url('../../assets/titleRight.png') no-repeat;
                    width: 74px;
                    height: 3px;
                    transform: translateY(50%);
                }
                p{
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 30px;
                    color: #686868;
                    margin-bottom: 109px;
                }
                p:nth-of-type(1){
                    margin-top: 28px;
                    margin-bottom: 0;
                }
            }
            .exploreList{
                width: 1200px;
                margin: 0 auto;
                &_item{
                    width: 100%;
                    height: 338px;
                    display: flex;
                    margin-top: 58px;
                    .exploreLeft{
                        width: 600px;
                        height: 338px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .exploreRigth{
                        width:600px;
                        height: 100%;
                        position: relative;
                        .item_Cire{
                            width: 100%;
                            height: 234px;
                            padding-top: 13px;
                            display: flex;
                            align-items: center;
                            img{
                                width: 413px;
                                height: 234px;
                            }
                            .imgMargin1{
                                width: 115px;
                                height: 73px;
                                margin: 0 24px 0 48px;
                            }
                            
                        }
                        .item_rightContent{
                            width: 100%;
                            position: absolute;
                            top: 101px;
                            left: 0;
                            text-indent: 108px;
                            font-size: 32px;
                            font-weight: bold;
                            line-height: 54px;
                            color: #000000;
                            b{
                                font-size: 40px;
                                color: #3BCD0D;
                            }
                        }
                    }
                    .type2{
                        .item_Cire{
                            .imgMargin1{
                                width: 115px;
                                height: 73px;
                                margin: 0 48px 0 24px;
                            }
                        }
                        .item_rightContent{
                            text-indent: 85px;
                        }   
                    }
                }
            }
        }
    }
    .quality{
        width: 100%;
        background-color: #F4F7F9;
        .qualityContain{
            width: 1200px;
            margin:0 auto;
            padding: 100px 0 180px;
            text-align: center;
            h1{
                display: inline-block;
                position: relative;
                font-size: 38px;
                font-weight: bold;
                color: #000000;
            }
            h1::before{
                position: absolute;
                top: 50%;
                left: -90px;
                display: block;
                content: '';
                background: url('../../assets/titleLeft.png') no-repeat;
                width: 74px;
                height: 3px;
                transform: translateY(50%);
            }
            h1::after{
                position: absolute;
                top: 50%;
                right: -90px;
                display: block;
                content: '';
                background: url('../../assets/titleRight.png') no-repeat;
                width: 74px;
                height: 3px;
                transform: translateY(50%);
            }
            .qualityList{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 128px;
                .qualityItem{
                    width: 260px;
                    height: 234px;
                    p{
                        font-size: 24px;
                        font-weight: bold;
                        line-height: 41px;
                        color: #000000;
                        text-align: center;
                        margin-top: 22px;
                    }
                    .itemImg{
                        width: 100%;
                        height: 188px;
                        position: relative;
                        border-radius: 12px;
                        overflow: hidden;
                        cursor: pointer;
                        img{
                            width:100%;
                            height: 100%;
                        }
                        .imgText{
                            position: absolute;
                            bottom: 0;
                            left:0;
                            width: 100%;
                            height: 0%;
                            background: rgba(59, 205, 13, .8);
                            display: flex;
                            align-items: center;
                            overflow: hidden;
                            transition:all 0.3s ease;
                            p{
                                font-size: 24px;
                                font-weight: bold;
                                line-height: 36px;
                                color: #FFFFFF;
                                padding: 0 20px;
                                box-sizing: border-box;
                                text-align: center;
                                margin-top: 0;
                            }
                        }
                    }
                    .itemImg:hover .imgText{
                        height: 100%;
                    }
                }
            }
        }
    }
    .textbook{
        width: 100%;
        background: url('../../assets/textBookBg.png') no-repeat bottom;
        &Contain{
            width: 1200px;
            margin: 0 auto;
            padding:100px 0 199px;
            height: 1280px;
            box-sizing: border-box;
            .textbookTitle{
                text-align: center;
                h1{
                    display: inline-block;
                    position: relative;
                    font-size: 38px;
                    font-weight: bold;
                    color: #000000;
                }
                h1::before{
                    position: absolute;
                    top: 50%;
                    left: -90px;
                    display: block;
                    content: '';
                    background: url('../../assets/titleLeft.png') no-repeat;
                    width: 74px;
                    height: 3px;
                    transform: translateY(50%);
                }
                h1::after{
                    position: absolute;
                    top: 50%;
                    right: -90px;
                    display: block;
                    content: '';
                    background: url('../../assets/titleRight.png') no-repeat;
                    width: 74px;
                    height: 3px;
                    transform: translateY(50%);
                }
                p{
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 30px;
                    color: #686868;
                    margin-bottom: 109px;
                }
                p:nth-of-type(1){
                    margin-top: 28px;
                    margin-bottom: 0;
                }
            }
            .textbookList{
                width: 100%;
                 margin-top: 45px;
                .listNav{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    &_item{
                        width: 228px;
                        height: 64px;
                        background: #F4F7F9;
                        border-radius: 8px;
                        text-align: center;
                        line-height: 64px;
                        font-size: 24px;
                        font-weight: 500;
                        color: #000000;
                        margin-right: 8px;
                    }
                    .textBookAcitve{
                        background: #3BCD0D;
                        color: #FFFFFF;
                    }
                    &_item:hover{
                        background: #3BCD0D;
                        color: #FFFFFF;
                    }
                }
                .listContain{
                    width: 100%;
                    margin-top: 60px;
                    background: #ffffff;
                    .textBook_item{
                        width: 100%;
                        height: 68px;
                        background: rgba(59, 205, 13, .3);
                        display: flex;
                        align-items: center;
                        margin-top: 2px;
                        span{
                            display: block;
                            width: 36px;
                            height: 36px;
                            background: #3BCD0D;
                            border-radius: 50%;
                            margin:0 14px 0 57px;
                            font-size: 24px;
                            font-weight: 500;
                            line-height: 36px;
                            text-align: center;
                            color: #FFFFFF;
                        }
                        p{
                            font-size: 24px;
                            font-weight: 500;
                            color: #000000;
                        }
                    }
                }
            }
        }
    }
    .teacher{
        &Contain{
            width: 1200px;
            margin: 0 auto;
            padding:100px 0 180px;
            .teacherTitle{
                text-align: center;
                h1{
                    display: inline-block;
                    position: relative;
                    font-size: 38px;
                    font-weight: bold;
                    color: #000000;
                }
                h1::before{
                    position: absolute;
                    top: 50%;
                    left: -90px;
                    display: block;
                    content: '';
                    background: url('../../assets/titleLeft.png') no-repeat;
                    width: 74px;
                    height: 3px;
                    transform: translateY(50%);
                    animation: rotaes 1s linear infinite alternate;
                }
                h1::after{
                    position: absolute;
                    top: 50%;
                    right: -90px;
                    display: block;
                    content: '';
                    background: url('../../assets/titleRight.png') no-repeat;
                    width: 74px;
                    height: 3px;
                    transform: translateY(50%);
                    animation: rotaes 1s linear infinite alternate;
                }
                p{
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 30px;
                    color: #686868;
                    margin-bottom: 109px;
                }
                p:nth-of-type(1){
                    margin-top: 28px;
                    margin-bottom: 0;
                }
            }
            .teacherBanan{
                width: 100%;
                height: 500px;
                position: relative;
                margin-top: 52px;
                overflow: hidden;
                .teacherChild{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    padding: 0 82px;
                    box-sizing: border-box;
                    .teacherLeft{
                        width: 350px;
                        height: 500px;
                        img{
                            width: 100%;
                            height: 100%;
                            border-radius: 20px;
                        }
                    }
                    .teacherRight{
                        width: 640px;
                        margin-left: 25px;
                        .teacherName{
                            display: flex;
                            align-items: flex-end;
                            width: 100%;
                            margin-bottom: 28px;
                            h1{
                                font-size: 40px;
                                font-weight: bold;
                                color: #000000;
                                line-height: 40px;
                            }
                            span{
                                display: inline-block;
                                padding: 4px 23px;
                                background: #D3F6CB;
                                border-radius: 13px;
                                font-size: 18px;
                                font-weight: 400;
                                color: #35AC10;
                                margin-left: 16px;
                            }
                        }
                        .teacherBrief{
                            width: 100%;
                            .label{
                                margin-top: 30px;
                            }
                            p{
                                font-size: 20px;
                                font-weight: 400;
                                line-height: 30px;
                                color: #686868;
                            }
                        }
                    }
                }
                .bananPre{
                    position: absolute;
                    top:50%;
                    left:0;
                    transform: translateY(-50%);
                    width: 68px;
                    height: 68px;
                    z-index: 2;
                    img{
                        width:100%;
                        height: 100%;
                    }
                }
                .bananNext{
                    position: absolute;
                    top:50%;
                    right:0;
                    transform: translateY(-50%);
                    width: 68px;
                    height: 68px;
                    z-index: 2;
                    img{
                        width:100%;
                        height: 100%;
                    }
                }
            }
            .teacherList{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                margin-top: 82px;
                justify-content: space-between;
                .teacherItem{
                    width: 225px;
                    height: 150px;
                    margin-bottom: 18px;
                    img{
                        width: 100%;
                        height: 100%;
                        transition: all 0.3s ease;
                    }
                }
                .teacherItem:hover img{
                    transform: scale(1.05);
                }
            }
        }  
    }
}
@keyframes rotaes{
    0%{transform:rotateY(0)}
    100%{transform:rotateY(180deg)}
}
</style>